<template>
  <div
    class="chipStatus"
    :class="{
      pendente: status === 'PENDENTE',
      confirmado: status === 'PARTICIPOU',
      desconfirmado: status === 'NAO_PARTICIPOU'
    }"
  >
    {{ statusClass }}
  </div>
</template>

<script>
export default {
  props: ["status"],
  computed: {
    statusClass() {
      switch (this.status) {
        case "PENDENTE":
          return "pendente";
        case "PARTICIPOU":
          return "participou";
        case "NAO_PARTICIPOU":
          return "faltou";
        default: {
          return "undefined";
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.chipStatus {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  user-select: none;
  &.pendente {
    background-color: rgb(150, 150, 150, 0.1);
  }
  &.confirmado {
    background-color: rgb(0, 100, 0, 0.5);
    color: white;
  }
  &.desconfirmado {
    background-color: rgba(200, 80, 80, 0.7);
    color: white;
  }
}
</style>
