<template>
  <div
    class="chipStatus"
    :class="{
      pendente: status === 'AGENDADA',
      confirmado: status === 'REALIZADA',
      desconfirmado: status === 'CANCELADA'
    }"
  >
    {{ getNameStatus() }}
  </div>
</template>

<script>
export default {
  name: "vChipStatusReuniao",
  props: ["status"],
  methods: {
    getNameStatus() {
      switch (this.status) {
        case "AGENDADA":
          return "agendada";
        case "REALIZADA":
          return "realizada";
        case "CANCELADA":
          return "cancelada";
        default: {
          return "undefined";
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.chipStatus {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  user-select: none;
  display: inline-block;
  &.pendente {
    background-color: rgb(150, 150, 150, 0.1);
  }
  &.confirmado {
    background-color: rgb(0, 100, 0, 0.5);
    color: white;
  }
  &.desconfirmado {
    background-color: rgba(200, 80, 80, 0.7);
    color: white;
  }
}
</style>
