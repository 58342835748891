<template>
  <v-dialog persistent v-model="dialog" max-width="450">
    <v-card class="pa-5 rounded-lg white">
      <v-row>
        <v-col cols="8">
          <v-modal-titulo>Editar reunião</v-modal-titulo>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div>
        <div class="mb-5">
          <v-text-field
            v-model="include.titulo"
            label="Titulo"
            hide-details
          ></v-text-field>
        </div>
        <div class="mb-6">
          <v-text-field
            v-model="include.data"
            type="date"
            hide-details
            label="Data"
          ></v-text-field>
        </div>
        <div id="status" class="mb-6">
          <div style="display: flex; flex-direction: row">
            <v-select
              ref="statusRefInput"
              v-model="include.status"
              :value="include.status"
              :items="statusList"
              item-text="text"
              item-value="value"
              label="Status"
              hide-details
            ></v-select>
          </div>
        </div>
        <div class="mb-5">
          <v-textarea
            outlined
            class="font"
            v-model="include.descricao"
            label="Descrição"
            counter="255"
            :rules="rulesDescription"
          ></v-textarea>
        </div>

        <div class="grid grid-col-1-1 justify-center align-center">
          <div>
            <v-btn
              :loading="loading"
              outlined
              color="secondaryDash"
              @click="$emit('close')"
              >Não alterar</v-btn
            >
          </div>
          <div class="text-right">
            <v-btn
              depressed
              dark
              :loading="loading"
              color="secondaryDash"
              @click="salvar()"
              >Salvar</v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import ServiceReuniao from "@/middleware/reuniaoController";

export default {
  props: ["item"],
  data() {
    return {
      dialog: true,
      loading: false,
      statusList: [
        { text: "Agendada", value: "AGENDADA" },
        { text: "Realizada", value: "REALIZADA" },
        { text: "Cancelada", value: "CANCELADA" }
      ],
      rulesDescription: [
        v => !!v || "Preenchimento obrigatório",
        v => v.length <= 255 || "Deve conter no maximo 255 caracteres"
      ],
      include: {
        descricao: null,
        data: null,
        titulo: null,
        id: null,
        status: null
      }
    };
  },
  computed: {
    countDescription() {
      return this.include.descricao.length;
    }
  },
  methods: {
    validate() {
      let validaData = moment(this.include.data);
      if (!validaData.isValid()) {
        this.$root.errorModal("Data inválida");
        return false;
      }
      if (!this.include.titulo) {
        this.$root.errorModal("Tiulo inválido");
        return false;
      }
      if (!this.include.descricao) {
        this.$root.errorModal("Descricão inválida");
        return false;
      }
      if (this.include.descricao.length > 255) {
        this.$root.errorModal("Descricão não pode passar dos 255 caracteres");
        return false;
      }
      return true;
    },
    salvar() {
      if (this.validate()) {
        this.loading = true;
        ServiceReuniao.updateReuniao(this.include)
          .then(() => {
            this.$root.sucessoModal("Alterado com sucesso");
            this.$emit("update");
            this.$emit("close");
          })
          .finally(() => {})
          .catch(err => {
            console.error(err);
            this.$root.errorModal(err.data);
          });
      }
    }
  },
  mounted() {
    this.loading = true;
    ServiceReuniao.getReuniao(this.item.id)
      .then(resp => {
        this.include = resp.data;
        this.include.data = moment(this.include.data).format("YYYY-MM-DD");
        this.loading = false;
      })
      .catch(err => {
        console.error(err.data);
        this.$root.errorModal(err.data);
        this.$emit("close");
      });
  }
};
</script>

<style></style>
