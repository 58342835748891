import axios from "axios";
import store from "@/store/index.js";

export default {
  /**
   * @param {number} convidadoId - id do convidado
   * @param {string} status - 'PENDENTE', 'PARTICIPOU', 'NAO_PARTICIPOU'
   * @returns {Promise}
   */
  updateConvidadoStatus(convidadoId, status) {
    return axios.put(`/v1/reuniao/convidado/${convidadoId}/status/${status}`);
  },
  incluirReuniao(payload) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post("/v1/reuniao", {
      data: payload.data,
      titulo: payload.titulo,
      descricao: payload.descricao,
      turmaId: turmaID,
      status: "AGENDADA"
    });
  },
  updateReuniao(payload) {
    return axios.put(`/v1/reuniao/${payload.id}`, {
      data: payload.data,
      descricao: payload.descricao,
      id: payload.id,
      status: payload.status,
      titulo: payload.titulo,
      turmaId: payload.turmaIds
    });
  },
  getReuniao(id) {
    return axios.get(`/v1/reuniao/${id}`);
  },
  getConvidados(reuniaoId) {
    return axios.get(`/v1/reuniao/${reuniaoId}/convidado`);
  },
  addConvidado(reuniaoId, payload) {
    return axios.post(`/v1/reuniao/${reuniaoId}/convidado`, {
      convidadoId: payload.convidadoId,
      email: payload.email,
      nome: payload.nome
    });
  },
  getPaginationReunioes(page, pageSize, filtros) {
    let turmaID = store.getters["aluno/getTurmaAtiva"];
    return axios.post("/v1/reuniao/pagination", {
      descending: true,
      //direction: "ASC",
      filtros: {
        id: null,
        titulo: filtros.titulo ? filtros.titulo : "",
        turmaId: turmaID
      },
      pageNo: page,
      pageSize: pageSize,
      sortBy: "titulo"
    });
  }
};
