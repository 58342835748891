<template>
  <div>
    <vTitulo>Reuniões</vTitulo>
    <div class="text-right mx-5" v-if="autorizacaoEditarCriar">
      <v-btn color="secondaryDash" depressed dark @click="novaReuniao()"
        >Nova reunião</v-btn
      >
    </div>
    <div id="pesquisar" style="padding: 20px">
      <v-card class="lowShadow borderLight pa-1">
        <div class="pa-3">
          <div class="containsSearchInputAndBtn">
            <div>
              <input
                class="customInput"
                v-model="filtros.titulo"
                placeholder="Pesquisar por titulo"
              />
            </div>
            <div class="containsBtnSearch">
              <v-btn
                outlined
                text
                color="primaryDash"
                class="mr-2"
                style="flex: 1"
                @click="onPressClean()"
              >
                <v-icon>
                  mdi-delete-circle
                </v-icon>
                <span class="ml-1">
                  Limpar
                </span>
              </v-btn>
              <v-btn
                style="flex: 1"
                outlined
                text
                color="primaryDash"
                @click="onPressSearch()"
                ><v-icon>
                  mdi-magnify
                </v-icon>
                <span class="ml-1">
                  Pesquisar
                </span></v-btn
              >
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div>
      <v-card class="pa-4 mx-5 lowShadow borderLight">
        <v-data-table
          :headers="headersReunioes"
          :items="listaReunioes"
          class="elevation-0"
          :items-per-page="itemsPerPage"
          item-key="titulo"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :loading="loadingListaReuniao"
          loading-text="carregando..."
        >
          <template v-slot:no-data>
            Não existe faturas registradas
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.arquivoNome">
                <td>
                  {{ filterDate(item.data) }}
                </td>
                <td>
                  <VChipStatusReuniao :status="item.status" />
                </td>
                <td style="min-width: 200px; padding: 5px;">
                  {{ item.titulo }}
                </td>

                <td style="min-width: 300px;padding: 5px;">
                  {{ item.descricao }}
                </td>

                <td class="text-right" style="min-width: 130px;">
                  <v-btn
                    color="secondaryDash"
                    depressed
                    dark
                    icon
                    style="margin-right: 5px"
                    v-if="autorizacaoEditarCriar"
                    @click="editarReuniao(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    @click="abrirControleDePresenca(item)"
                    icon
                    color="secondaryDash"
                    depressed
                    dark
                  >
                    <v-icon>mdi-account-multiple</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <div class="pagination">
          <v-pagination
            :disabled="loadingListaReuniao"
            color="#7275FF"
            circle
            :length="pageCount"
            v-model="page"
          ></v-pagination>
        </div>
      </v-card>
    </div>
    <div style="transform: translateZ(0); ">
      <vModalControleDePresenca
        v-if="showModalControlePresenca"
        :item="itemPayload"
        @update="onPressSearch()"
        @close="closeModal()"
      />
      <vModalNovaReuniao
        v-if="showModalNovaReuniao"
        @update="onPressSearch()"
        @close="closeModal()"
      />
      <vModelEditarReuniao
        :item="itemPayload"
        v-if="showModalEditarReuniao"
        @update="onPressSearch()"
        @close="closeModal()"
      />
    </div>
  </div>
</template>

<script>
import vModelEditarReuniao from "./vModalEditarReuniao.vue";
import vModalNovaReuniao from "./vModalNovaReuniao.vue";
import vModalControleDePresenca from "./vModalControleDePresenca.vue";
import ServiceReuniao from "@/middleware/reuniaoController";
import moment from "moment";
import VChipStatusReuniao from "./vChipStatusReuniao.vue";

export default {
  components: {
    vModalControleDePresenca: vModalControleDePresenca,
    vModelEditarReuniao: vModelEditarReuniao,
    vModalNovaReuniao: vModalNovaReuniao,
    VChipStatusReuniao
  },
  data() {
    return {
      showFilters: false,
      showModalEditarReuniao: false,
      showModalControlePresenca: false,
      showModalNovaReuniao: false,
      itemPayload: null,
      filtros: {
        titulo: null
        //data: null
      },
      listaReunioes: [],
      headersReunioes: [
        { text: "Data", value: "data", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "Titulo", value: "titulo", align: "left" },
        { text: "Descrição", value: "descricao", align: "left" },
        { text: "Ações", value: "", align: "right" }
      ],
      itemsPerPage: 10,
      page: 1,
      pageCount: 0,
      pageSize: 20,
      loadingListaReuniao: true
    };
  },
  computed: {
    autorizacaoEditarCriar() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "ADMIN";
    }
  },
  watch: {
    page: function() {
      this.getDataTable();
    }
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getStatusReuniao(status) {
      if (status === "AGENDADA") {
        return "Agendada";
      } else if (status === "REALIZADA") {
        return "Realizada";
      } else if (status === "CANCELADA") {
        return "Cancelada";
      } else {
        return "Undefined";
      }
    },
    filterDate(data) {
      if (data) {
        return moment(data).format("DD/MM/YYYY");
      } else {
        return "S/DATA";
      }
    },
    novaReuniao() {
      this.showModalNovaReuniao = true;
    },
    abrirControleDePresenca(item) {
      this.itemPayload = item;
      this.showModalControlePresenca = true;
    },
    editarReuniao(item) {
      this.itemPayload = item;
      this.showModalEditarReuniao = true;
    },
    closeModal() {
      this.showModalEditarReuniao = false;
      this.showModalControlePresenca = false;
      this.showModalNovaReuniao = false;
      this.itemPayload = null;
    },
    getDataTable() {
      this.loadingListaReuniao = true;

      ServiceReuniao.getPaginationReunioes(
        this.page - 1,
        this.pageSize,
        this.filtros
      )
        .then(resp => {
          this.listaReunioes = resp.data.content;
          this.pageCount = resp.data.totalPages;
        })
        .finally(() => {
          this.loadingListaReuniao = false;
        })
        .catch(err => {
          console.error(err);
        });
    },
    onPressClean() {
      this.filtros.titulo = null;
      //this.filtros.data = null;
    },
    onPressSearch() {
      this.getDataTable();
    }
  }
};
</script>
<style lang="scss" scoped>
.customInput {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 12px;
  outline: none;
  width: 100%;
  font-size: 15px;
  &:focus {
    outline-color: rgba(0, 70, 200, 0.1);
    outline-style: solid;
  }
}
.containsSearchInputAndBtn {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.containsBtnSearch {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.tituloModalFiltros {
  font-weight: medium;
  color: rgba(150, 150, 150, 1);
}
</style>
