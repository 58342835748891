<template>
  <v-dialog persistent v-model="dialog" max-width="450">
    <v-card class="pa-5 rounded-lg white">
      <v-row justify="center" align="center">
        <v-col cols="8">
          <v-modal-titulo>Nova reunião</v-modal-titulo>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div>
        <div>
          <v-text-field v-model="include.titulo" label="Titulo"></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="include.data"
            type="date"
            label="Data"
          ></v-text-field>
        </div>
        <div>
          <v-textarea
            outlined
            v-model="include.descricao"
            label="Descrição"
          ></v-textarea>
        </div>
        <v-row justify="center" align="center" class="text-center">
          <v-col></v-col>
          <v-col class="text-right">
            <v-btn
              :loading="loading"
              depressed
              dark
              color="secondaryDash"
              @click="salvar()"
              >Cadastrar</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import ServiceReuniao from "@/middleware/reuniaoController";

export default {
  props: ["item"],
  data() {
    return {
      dialog: true,
      loading: false,
      include: {
        descricao: null,
        data: null,
        titulo: null
      }
    };
  },
  methods: {
    validate() {
      let validaData = moment(this.include.data);
      if (!validaData.isValid()) {
        this.$root.errorModal("Data inválida");
        return false;
      }
      if (!this.include.titulo) {
        this.$root.errorModal("Tiulo inválido");
        return false;
      }
      if (!this.include.descricao) {
        this.$root.errorModal("Descricão inválida");
        return false;
      }
      return true;
    },
    salvar() {
      if (this.validate()) {
        this.loading = true;
        ServiceReuniao.incluirReuniao(this.include)
          .then(() => {
            this.$root.sucessoModal("Reunião criada com sucesso");
            this.$emit("update");
            this.$emit("close");
          })
          .finally(() => {
            this.loading = false;
          })
          .catch(err => {
            console.error(err.data);
            this.$root.errorModal(err.data);
          });
      }
    }
  },
  mounted() {
    //logica para definir a data atual no include.data para o calendário
    //this.include.data = moment().format("YYYY-MM-DD");
  }
};
</script>

<style></style>
