<template>
  <v-dialog v-model="dialog" persistent max-width="700">
    <v-card class="pa-5 pt-3">
      <div class="grid grid-col-1-auto">
        <div>
          <vModalTitulo>
            Controle de Presença
          </vModalTitulo>
        </div>
        <div>
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-if="loadingInterface">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
      <div v-else>
        <div>
          <div class="text-capitalize" style="color: rgba(70,70,100,1);">
            {{ reuniao.titulo }}
          </div>
        </div>
        <div class="containHeader" style="color: rgba(70,70,100,1);">
          <v-row class="pb-1 px-0">
            <v-col cols="12" sm="6" align-self="center">
              <div>
                <span class="label">Data: </span>
                <span class="labelValue ml-2">{{
                  formatDate(reuniao.data)
                }}</span>
              </div>
              <div>
                <span class="label">Total de confirmados: </span>
                <span class="labelValue ml-2">{{
                  getTotalConvidadosConfirmados
                }}</span>
              </div>
            </v-col>
            <v-col cols="12" sm="6" align-self="center" class="text-center">
              <div
                class="mb-2"
                style="font-weight: 400; text-transform: uppercase; font-size: 14px;"
              >
                Status da reunião
              </div>
              <VChipStatusReuniao :status="getReuniaoDados.status" />
            </v-col>
          </v-row>
        </div>
        <div class="mb-2">
          <div class="containDescricao">
            <div class="containDescricaoIcon">
              <v-icon size="35" color="primaryDash"
                >mdi-format-quote-open</v-icon
              >
            </div>
            {{ reuniao.descricao }}
          </div>
        </div>
        <div
          style="border-bottom: 1px solid rgba(50,50,50,0.1)"
          v-if="getPermissaoParaNovoConvidado()"
        ></div>
        <div
          id="novoConvite"
          class="pt-3"
          v-if="getPermissaoParaNovoConvidado()"
        >
          <div>
            <div style="margin-bottom: 10px; font-weight: 600">
              Novo convidado
            </div>
            <div class="grid grid-col-1-1" style="gap: 10px">
              <input
                class="customInput"
                v-model="convidadoEmail"
                placeholder="Email do convidado"
              />
              <input
                class="customInput"
                v-model="convidadoNome"
                placeholder="Nome do convidado"
              />
            </div>
            <div class="containsBtnSearch">
              <v-btn
                depressed
                dark
                text
                color="grey"
                class="mr-2"
                @click="onPressClean()"
              >
                <v-icon>
                  mdi-delete-circle
                </v-icon>
                <span class="ml-1">
                  Limpar
                </span>
              </v-btn>

              <v-btn
                depressed
                color="primaryDash"
                dark
                @click="onPressAddConvidado()"
              >
                <span class="ml-1">
                  Convidar
                </span>
              </v-btn>
            </div>
          </div>
        </div>
        <div
          style="border-bottom: 1px solid rgba(50,50,50,0.1); margin-top: 10px"
        ></div>
        <div id="list">
          <v-data-table
            style="max-height: 400px;overflow-y: auto"
            max-height="200px"
            :headers="headerTable"
            :items="listaParticipantes"
            class="elevation-0"
            :items-per-page="itemsPerPage"
            item-key="titulo"
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :loading="loadingTable"
            loading-text="carregando..."
          >
            <template v-slot:no-data>
              Sem participantes
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.arquivoNome">
                  <td class="text-center">
                    <vPerfilPhotoOrLetterInDataTable
                      :nome="item.nome"
                      :url="item.url"
                    />
                  </td>
                  <td style="font-size: 13px !important">
                    {{ item.nome | hasNull }}
                  </td>
                  <td style="font-size: 13px !important">
                    {{ item.email | hasNull }}
                  </td>
                  <td class="text-right">
                    <div v-if="getPermissaoParaAcao(item)">
                      <div v-if="item.status === 'PENDENTE'">
                        <v-btn
                          title="Participei"
                          depressed
                          small
                          color="green darken-1"
                          dark
                          @click="confirmarPresenca(item)"
                          :loading="loadingActions"
                        >
                          <v-icon small>
                            mdi-check-all
                          </v-icon>
                          <span style="font-size: 11px; margin-left: 2px">
                            Confirmar
                          </span>
                        </v-btn>
                      </div>
                      <div v-if="item.status === 'PARTICIPOU'">
                        <v-btn
                          title="Não participei"
                          color="red darken-1"
                          depressed
                          small
                          dark
                          @click="desconfirmarPresenca(item)"
                          :loading="loadingActions"
                        >
                          <v-icon small>mdi-close</v-icon>
                          <span style="font-size: 11px; margin-left: 2px">
                            Desconfirmar
                          </span>
                        </v-btn>
                      </div>
                      <div v-if="item.status === 'NAO_PARTICIPOU'">
                        <v-btn
                          title="Participei"
                          depressed
                          small
                          color="green darken-1"
                          dark
                          @click="confirmarPresenca(item)"
                          :loading="loadingActions"
                        >
                          <v-icon small>
                            mdi-check-all
                          </v-icon>
                          <span style="font-size: 11px; margin-left: 2px">
                            Confirmar
                          </span>
                        </v-btn>
                      </div>
                    </div>
                    <div v-else>
                      <!-- apenas visualizar -->
                      <VChipStatusPresenca :status="item.status" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import vModalTitulo from "@/components/vModalTitulo.vue";
import ServiceReuniao from "@/middleware/reuniaoController";
import moment from "moment";
import vPerfilPhotoOrLetterInDataTable from "@/components/user/PerfilPhotoOrLetterInDataTable.vue";
import VChipStatusPresenca from "./vChipStatusPresenca.vue";
import VChipStatusReuniao from "./vChipStatusReuniao.vue";

export default {
  components: {
    vModalTitulo,
    vPerfilPhotoOrLetterInDataTable,
    VChipStatusPresenca,
    VChipStatusReuniao
  },
  props: ["item"],
  data() {
    return {
      reuniao: {},
      loadingActions: false,
      dialog: true,
      page: 0,
      pageCount: 0,
      itemsPerPage: 500,
      loadingTable: true,
      loadingInterface: true,
      convidadoEmail: null,
      convidadoNome: null,
      headerTable: [
        { text: "Foto", value: "fotoUrl", sortable: false, align: "left" },
        { text: "Nome", value: "nome" },
        { text: "Email", value: "email" },
        { text: "Ações", value: "", sortable: false, align: "right" }
      ],
      listaParticipantes: []
    };
  },
  filters: {
    hasNull(value) {
      if (value) {
        return value;
      } else {
        return "S/DADOS";
      }
    }
  },
  computed: {
    getReuniaoDados() {
      return this.reuniao;
    },
    getTotalConvidadosConfirmados() {
      let total = 0;
      this.listaParticipantes.forEach(participante => {
        if (participante.status == "PARTICIPOU") {
          total += 1;
        }
      });
      if (total === 0) {
        return 0;
      }
      return `${total}`.padStart(2, "0");
    }
  },
  methods: {
    getPermissaoParaNovoConvidado() {
      if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] !== "ADMIN") {
        return false;
      }
      if (this.reuniao.status !== "AGENDADA") {
        return false;
      }
      return true;
    },
    getPermissaoParaAcao(item) {
      if (
        this.reuniao.status === "REALIZADA" ||
        this.reuniao.status === "CANCELADA"
      ) {
        return false;
      } else if (
        this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "ADMIN"
      ) {
        return true;
      } else if (this.$store.getters["aluno/getUser"].id === item.id) {
        return true;
      } else {
        return false;
      }
    },
    onPressAddConvidado() {
      if (!this.convidadoEmail || !this.convidadoNome) {
        this.$root.errorModal(
          "Preencha todos os campos para adicionar um novo convidado"
        );
        return;
      }
      const requestPayload = {
        email: this.convidadoEmail,
        nome: this.convidadoNome
      };
      ServiceReuniao.addConvidado(this.reuniao.id, requestPayload)
        .then(() => {
          this.$root.sucessoModal("Convidado com sucesso!");
          this.onPressClean();
          this.getConvidados();
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
        });
    },
    onPressClean() {
      this.convidadoEmail = null;
      this.convidadoNome = null;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    confirmarPresenca(item) {
      this.loadingActions = true;
      ServiceReuniao.updateConvidadoStatus(item.id, "PARTICIPOU")
        .then(() => {
          this.$root.sucessoModal("Alterado com sucesso!");
          this.getConvidados();
        })
        .finally(() => {
          this.loadingActions = false;
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
        });
    },
    desconfirmarPresenca(item) {
      this.loadingActions = true;
      ServiceReuniao.updateConvidadoStatus(item.id, "NAO_PARTICIPOU")
        .then(() => {
          this.$root.sucessoModal("Alterado com sucesso!");
          this.getConvidados();
        })
        .finally(() => {
          this.loadingActions = false;
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
        });
    },
    getConvidados() {
      this.loadingTable = true;
      ServiceReuniao.getConvidados(this.item.id)
        .then(resp => {
          this.listaParticipantes = resp.data;
        })
        .finally(() => {
          this.loadingTable = false;
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
          this.$emit("close");
        });
    }
  },
  mounted() {
    ServiceReuniao.getReuniao(this.item.id)
      .then(resp => {
        this.reuniao = resp.data;
        this.loadingInterface = false;
        this.getConvidados();
      })

      .catch(err => {
        console.error(err.data);
        this.$emit("close");
      });
  }
};
</script>

<style lang="scss" scoped>
.containDescricaoIcon {
  position: absolute;
  margin-top: -31px;
  margin-left: -6px;
}

.label {
  font-weight: 400;
  font-size: 15px;
}

.labelValue {
  font-weight: 800;
  font-size: 15px;
  font-family: monospace !important;
}

.containDescricao {
  font-weight: 300;
  font-size: 13px;
  background-color: rgba(70, 70, 100, 0.05);
  border-radius: 5px;
  padding: 14px;
  position: relative;
  border: 1px solid rgba(70, 70, 100, 0.05);
}

.customInput {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 12px;
  outline: none;
  width: 100%;
  font-size: 15px;

  &:focus {
    outline-color: rgba(0, 70, 200, 0.1);
    outline-style: solid;
  }
}

.containsSearchInputAndBtn {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.containsBtnSearch {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center;
}

.containHeader {
  padding-bottom: 10px;
}

.titulo {
  color: rgba(70, 70, 70, 1);
  text-transform: capitalize;
  font-weight: 300;
  padding-bottom: 5px;
  padding-top: 5px;
}

.dataLabel {
  font-weight: 300;
}

.totalLabel {
  font-weight: 300;
}

.boxRounded {
  display: inline-flex;
  font-size: 20px;
  color: white;
  background-color: rgb(31, 51, 139);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10%;
  margin: 6px;
}

.customBtn {
  padding: 8px;
  border-radius: 6px;
  background-color: #fff;
  color: #37447e;
  border: 1px solid #37447e;
}

.containMenuItens {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  .v-btn {
    margin-right: 5px;
  }
}
</style>
